import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../constants/urls";

const initialState = {
  companies: [],
  isLoading: false,
};

const user = JSON.parse(localStorage.getItem("user"));
const bearerToken = user?.token;

export const createNewCompany = createAsyncThunk(
  "/companies",
  async (company, { rejectWithValue }) => {
    const url = apiBaseUrl + "/companies";
    try {
      const response = await axios.post(
        url,
        { company: company },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllCompanies = createAsyncThunk(
  "/companies",
  async (_, { rejectWithValue }) => {
    const url = apiBaseUrl + "/companies";
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCompanies.fulfilled, (state, action) => {
        state.companies = action.payload.data.companies_data;
        state.isLoading = false;
      })
      .addCase(getAllCompanies.rejected, (state, action) => {
        state.isLoading = true;
      });
  },
});

export default companySlice.reducer;
