import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import NavigationDrawer from "./components/NavigationDrawer.jsx";
import { useSelector } from "react-redux";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const PrivateRoute = () => {
  const { user } = useSelector((state) => state.user);

  return user ? (
    <Box sx={{ display: "flex" }}>
      <NavigationDrawer />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
