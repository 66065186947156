import Box from "@mui/material/Box";
import TopAppBar from "./TopAppBar";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { createNewCompany } from "../redux/features/company";
import { useNavigate } from "react-router-dom";
import { palette } from "../theme/palette.js";
import { toast } from "react-toastify";

const NewCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [company, setCompany] = useState({
    name: "",
    email: "",
    subject: "",
    type: "",
    template: "",
  });

  const handleChange = (event) => {
    setCompany({
      ...company,
      [event.target.name]: event.target.value,
    });
  };

  const handleCompanySave = (event) => {
    event.preventDefault();

    if (company.name === "") {
      toast.error("Please enter company name");
    } else {
      try {
        dispatch(createNewCompany(company));
        toast.success("Company added successfully");
        navigate("/companies");
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <Box>
      <TopAppBar heading="Companies" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Create Company
        </Typography>
      </Box>

      <Grid
        container
        columnSpacing={10}
        rowSpacing={4}
        paddingRight={20}
        paddingTop={5}
      >
        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
            }}
          >
            Company Name
          </Typography>
          <TextField
            value={company.name}
            fullWidth
            id="name"
            name="name"
            placeholder="Company Name"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>
        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
            }}
          >
            Company Email
          </Typography>
          <TextField
            value={company.email}
            fullWidth
            id="email"
            name="email"
            placeholder="Company Email"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>
        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
            }}
          >
            Subject
          </Typography>
          <TextField
            value={company.subject}
            fullWidth
            id="subject"
            name="subject"
            placeholder="Subject"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>
        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
            }}
          >
            Type of Company
          </Typography>
          <TextField
            value={company.type}
            fullWidth
            id="type"
            name="type"
            placeholder="Company Type"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>
        <Grid item xs={8} md={6}>
          <Typography
            color={palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
            }}
          >
            Select Template
          </Typography>
          <TextField
            value={company.template}
            fullWidth
            id="template"
            name="template"
            placeholder="Template"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginRight: 10,
          marginTop: 20,
          minHeight: "100%",
        }}
      >
        <Button
          variant="contained"
          onClick={handleCompanySave}
          sx={{ width: 160 }}
        >
          Create Company
        </Button>
      </Box>
    </Box>
  );
};

export default NewCompany;
