import { red, blueGrey, grey } from "@mui/material/colors";

const purple = "#333D7A";
const white = "#ffffff";
const black = "#000000";
const gray = "#8E8E8E";
const lightGray = "#D8D8D8";
const mediumGray = "#DCDCDC";

export const palette = {
  primary: {
    main: purple,
    dark: black,
    white: white,
    gray: gray,
    lightGray: lightGray,
  },
  secondary: {
    main: white,
    mediumGray: mediumGray,
  },
  error: {
    contrastText: white,
    dark: red[900],
    main: red[600],
    light: red[400],
  },
  link: purple[800],
  icon: blueGrey[600],
  background: {
    default: white,
    paper: white,
  },
  divider: grey[200],
};
