import { palette } from "../palette";

export const MuiDrawer =  {
  styleOverrides: {
    paper: {
      backgroundColor: palette.primary.main,
      color: palette.primary.white,
    },
  }
};
