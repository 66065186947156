import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { loginUser } from "../redux/features/user";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { palette } from "../theme/palette.js";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [session, setSession] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = async (event) => {
    setSession({
      ...session,
      [event.target.name]: event.target.value,
    });
  };

  const clearFields = () => {
    setSession({
      email: "",
      password: "",
    });
  };

  const handleSubmit = async (event) => {
    console.log("LOGIN");
    event.preventDefault();

    if (session.email === "") {
      toast.error("Please enter email");
    } else if (session.password === "") {
      toast.error("Please enter password");
    } else {
      try {
        await dispatch(loginUser(session)).unwrap();
        toast.success("Logged in successfully");
        navigate("/");
      } catch (error) {
        clearFields();
        toast.error(error?.message);
      }
    }
  };

  return (
    <Box
      style={{
        backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={false} sm={4} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "100px",
            }}
          >
            <Typography
              sx={{
                fontSize: "48.49px",
                fontWeight: 600,
                lineHeight: "58.69px",
                color: palette.primary.white,
                marginBottom: "100px",
              }}
            >
              ExamFit
            </Typography>
            <Typography
              sx={{
                fontSize: "48.49px",
                fontWeight: 600,
                lineHeight: "58.69px",
                color: palette.primary.white,
              }}
            >
              Welcome To, ExamFit
            </Typography>
            <Typography
              sx={{
                fontSize: "24px",
                lineHeight: "29.05px",
                color: palette.primary.white,
              }}
            >
              Please enter your login credentials to access your account
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={8} md={6} elevation={6} padding="40px">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              paddingX: 12,
              borderRadius: "24px",
            }}
            component={Paper}
          >
            <Box
              sx={{
                marginBottom: "40px",
              }}
            >
              <Typography
                color={palette.primary.main}
                sx={{ fontSize: "34px", fontWeight: 600, lineHeight: "40.8px" }}
              >
                Login
              </Typography>
              <Typography
                color={palette.black}
                sx={{
                  fontSize: "17.74px",
                  fontWeight: "normal",
                  lineHeight: "21.47px",
                }}
              >
                Enter Your Information
              </Typography>
            </Box>

            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                }}
              >
                Email
              </Typography>
              <TextField
                value={session.email}
                required
                fullWidth
                id="email"
                name="email"
                placeholder="Email"
                autoComplete="email"
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  marginTop: 0,
                }}
              />
              <Typography
                color={palette.primary.gray}
                sx={{
                  fontSize: "13.74px",
                  lineHeight: "16.63px",
                  marginBottom: "10px",
                  marginTop: "20px",
                }}
              >
                Password
              </Typography>
              <TextField
                value={session.password}
                required
                fullWidth
                id="password"
                name="password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  ),
                }}
                sx={{
                  marginTop: 0,
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "30px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    sx={{ color: palette.primary.lightGray }}
                  />
                }
                label="Remember me"
                sx={{
                  color: palette.primary.gray,
                  fontSize: "18.33px",
                }}
              />

              <Link
                href="#"
                color={palette.primary.main}
                sx={{ textDecoration: "none" }}
              >
                Forgot password?
              </Link>
            </Box>

            <Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  marginBottom: "30px",
                }}
              >
                Login
              </Button>
              <Typography
                sx={{
                  fontSize: "18.33px",
                  fontWeight: 300,
                  lineHeight: "24.12px",
                }}
              >
                By clicking Login, you are agreeing to the ExamFit{" "}
                <Link
                  href="#"
                  color={palette.primary.main}
                  sx={{ textDecoration: "none" }}
                >
                  Terms of Use
                </Link>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
