import { palette } from "../palette";

export const MuiButton = {
  styleOverrides: {
    contained: {
      borderRadius: "13px",
      padding: "12px 20px",
      width: "100% ",
      color: palette.primary.white,
      backgroundColor: palette.primary.main,
      fontFamily: "inter, sans-serif",
      fontSize: 14,
      fontStretch: "normal",
      letterSpacing: 0.36,
      fontWeight: 700,
      textTransform: "capitalize",
      boxShadow: "3px 6px 14px 0 #0038FF33",
      "&:hover": {
        backgroundColor: palette.primary.main,
        color: palette.primary.white,
      },
      "&.Mui-disabled": {
        backgroundColor: palette.primary.main,
        boxShadow: "3px 6px 14px 0 #0038FF33",
        color: palette.primary.white,
        opacity: 1,
      },
    },

    text: {
      textDecoration: "none",
      fontFamily: "inter, sans-serif",
      padding: "6px 0px",
      fontSize: 18,
      fontWeight: 700,
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: palette.primary.main,
      textTransform: "none",
      "&:hover": {
        textDecoration: "none",
        backgroundColor: "transparent",
      },
    },
  },
};
