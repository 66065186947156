import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import TopAppBar from "./TopAppBar";
import { Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { inviteNewUsers } from "../redux/features/user";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getAllCompanies } from "../redux/features/company";
import { toast } from "react-toastify";

const NewUser = () => {
  const { user } = useSelector((state) => state.user);
  const companies = useSelector((state) => state.company.companies);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [newUser, setNewUser] = useState({
    email: "",
    role: "",
    company: "",
  });

  const uberAdminUserRoles = [
    {
      title: "Admin",
      value: "admin",
    },
  ];

  const adminUserRoles = [
    {
      title: "Examiner",
      value: "examiner",
    },
    {
      title: "Reviewer",
      value: "reviewer",
    },
  ];

  const handleChange = (event) => {
    setNewUser({
      ...newUser,
      [event.target.name]: event.target.value,
    });
  };

  const handleUserInvitation = (event) => {
    event.preventDefault();

    if (newUser.email === "") {
      toast.error("Please enter email");
    } else {
      try {
        dispatch(inviteNewUsers(newUser)).unwrap();
        toast.success("User account invitation sent");
        navigate("/users");
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  useEffect(() => {
    dispatch(getAllCompanies());
  }, []);

  return (
    <Box>
      <TopAppBar heading="Companies" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Invite Users
        </Typography>
      </Box>

      <Grid
        container
        columnSpacing={10}
        rowSpacing={4}
        paddingRight={20}
        paddingTop={5}
      >
        <Grid item xs={8} md={6}>
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
            }}
          >
            User Email
          </Typography>
          <TextField
            value={newUser.email}
            fullWidth
            id="email"
            name="email"
            placeholder="Enter User Email"
            onChange={handleChange}
            sx={{
              marginTop: 0,
            }}
          />
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
            }}
          >
            User Role
          </Typography>

          <FormControl fullWidth>
            <Select
              displayEmpty
              id="role"
              name="role"
              variant="outlined"
              value={newUser.role}
              onChange={handleChange}
              renderValue={(selected) => {
                if (selected === "") {
                  return (
                    <span style={{ color: theme.palette.primary.gray }}>
                      Select Role
                    </span>
                  );
                }

                var selectedOption = "";

                if (user.role === "uber_admin") {
                  selectedOption = uberAdminUserRoles?.find(
                    (role) => role.value === selected
                  );
                } else if (user.role === "admin") {
                  selectedOption = adminUserRoles?.find(
                    (role) => role.value === selected
                  );
                }

                return selectedOption ? selectedOption.title : "";
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {user.role === "uber_admin"
                ? uberAdminUserRoles.map((role, index) => (
                    <MenuItem key={index} value={role.value}>
                      {role.title}
                    </MenuItem>
                  ))
                : adminUserRoles.map((role, index) => (
                    <MenuItem key={index} value={role.value}>
                      {role.title}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography
            color={theme.palette.primary.gray}
            sx={{
              fontSize: "13.74px",
              lineHeight: "16.63px",
              marginBottom: "10px",
            }}
          >
            Company
          </Typography>

          <FormControl fullWidth>
            <Select
              displayEmpty
              id="company"
              name="company"
              value={newUser.company}
              onChange={handleChange}
              renderValue={(selected) => {
                if (selected === "") {
                  return (
                    <span style={{ color: theme.palette.primary.gray }}>
                      Select Company
                    </span>
                  );
                }
                const selectedCompany = companies?.find(
                  (company) => company.id === selected
                );
                return selectedCompany ? selectedCompany.name : "";
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {companies &&
                companies.map((company) => (
                  <MenuItem value={company.id}>{company.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginRight: 10,
          marginTop: 20,
          minHeight: "100%",
        }}
      >
        <Button
          variant="contained"
          onClick={handleUserInvitation}
          sx={{ width: 160 }}
        >
          Invite User
        </Button>
      </Box>
    </Box>
  );
};

export default NewUser;
