import Box from "@mui/material/Box";
import TopAppBar from "./TopAppBar";
import { Typography, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAllCompanies } from "../redux/features/company";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useSelector } from "react-redux";
import GradientCircularProgress from "../elements/GradientCircularProgress";

const CompanyCard = ({ name, createdAt }) => {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          {name}
        </Typography>

        <Box sx={{ display: "flex" }}></Box>
      </CardContent>
      <CardActions>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Created: {dayjs(createdAt).format("MMMM DD, YYYY")}
        </Typography>
        <Button>Edit Details</Button>
      </CardActions>
    </Card>
  );
};

const Companies = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const companies = useSelector((state) => state.company.companies);
  const isLoading = useSelector((state) => state.company.isLoading);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  return isLoading ? (
    <GradientCircularProgress open={isLoading} />
  ) : (
    <Box>
      <TopAppBar heading="Companies" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: "30px",
            lineHeight: "28px",
            fontWeight: 700,
          }}
        >
          Companies
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
          }}
        >
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search google maps" }}
            />
          </Paper>
          <Link>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigate("/companies/add-company");
              }}
            >
              Create Company
            </Button>
          </Link>
        </Box>
      </Box>

      <ImageList sx={{ width: "100%", height: "100%" }} cols={4}>
        {companies &&
          companies.map((company, index) => (
            <ImageListItem key={index}>
              <CompanyCard name={company.name} createdAt={company.created_at} />
            </ImageListItem>
          ))}
      </ImageList>
    </Box>
  );
};

export default Companies;
