import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user";
import utilsReducer from "./features/utils";
import companyReducer from "./features/company";

export const store = configureStore({
  reducer: {
    user: userReducer,
    utils: utilsReducer,
    company: companyReducer,
  },
});
