import "./App.css";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import PrivateRoute from "./PrivateRoute";
import Companies from "./components/Companies.jsx";
import Users from "./components/Users.jsx";
import NewCompany from "./components/NewCompany.jsx";
import NewUser from "./components/NewUser.jsx";
import lightTheme from "./theme";
import PublicRoute from "./PublicRoute.js";
import { ToastContainer } from 'react-toastify';
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <ToastContainer />
      <CssBaseline />
      <Router>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/users" element={<Users />} />
            <Route path="/companies/add-company" element={<NewCompany />} />
            <Route path="/users/add-user" element={<NewUser />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
