import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";

import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import TopAppBar from "./TopAppBar";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const CompanyCard = () => {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Company Name
        </Typography>
        <Typography variant="h5" component="div">
          Company Name
        </Typography>

        <Box sx={{ display: "flex" }}></Box>
      </CardContent>
      <CardActions>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Created: 12/10/2024
        </Typography>
        <Button>Edit Details</Button>
      </CardActions>
    </Card>
  );
};

const Dashboard = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <TopAppBar heading="Dashboard" />
      <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>
        Dashboard
      </Typography>
    </Box>
  );
};

export default Dashboard;
