import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../constants/urls";

const initialState = {
  user: null,
  usersList: [],
};

initialState.user = JSON.parse(localStorage.getItem("user"));
const user = JSON.parse(localStorage.getItem("user"));
const bearerToken = user?.token;

export const loginUser = createAsyncThunk(
  "/auth/login",
  async (session, { rejectWithValue }) => {
    const url = apiBaseUrl + "/auth/login";
    try {
      const response = await axios.post(url, session, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const inviteNewUsers = createAsyncThunk(
  "/companies/:id/invite_user",
  async (user, { rejectWithValue }) => {
    const url = apiBaseUrl + `/companies/${user.company}/invite_user`;
    try {
      const response = await axios.post(url, user, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "/users",
  async (_, { rejectWithValue }) => {
    const url = apiBaseUrl + "/users";
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload.data;
        localStorage.setItem("user", JSON.stringify(state.user));
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.usersList = action.payload.data.users_data;
      });
  },
});

export default userSlice.reducer;
export const { logout } = userSlice.actions;
